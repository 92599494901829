<template>
  <div class="strapi-spacer" :class="[`strapi-spacer--${data.Spacing}`]"></div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});
</script>

<style lang="scss">
.strapi-spacer {
  height: 0;

  &--extra-small {
    height: clamp(0.5rem, 1vw, 1rem);
  }
  &--small {
    height: clamp(1rem, 2.5vw, 2.5rem);
  }
  &--medium {
    height: clamp(2rem, 5vw, 5rem);
  }
  &--large {
    height: clamp(4rem, 10vw, 10rem);
  }
  &--extra-large {
    height: clamp(8rem, 20vw, 20rem);
  }
}
</style>
